import React from 'react';
import './App.css';

class Statistics extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			time: {
				seconds: "00",
				minutes: "00",
				hours: "00"
			},
			gameStartedAt: this.props.gameStartedAt,
			gameRunning: this.props.gameRunning
		}
	}

	componentDidMount() {
		setInterval(() => {
			this.setState({
				time: {
					seconds: (Math.floor((Date.now() - this.props.gameStartedAt) / 1000)) % 60,
					minutes: (Math.floor((Math.floor((Date.now() - this.props.gameStartedAt) / 1000)) / 60)) % 60,
					hours: Math.floor((Math.floor((Math.floor((Date.now() - this.props.gameStartedAt) / 1000)) / 60)) / 60),
				}
			})
		}, 1000);
	}

	render() {
		return (
			<div
				className="statistics"
			>
				<p
					className="lable"
				>Time:</p>
				<p
					className="value"
				>{this.props.gameRunning ? `${('0' + this.state.time.hours).slice(-2)}:${('0' + this.state.time.minutes).slice(-2)}:${('0' + this.state.time.seconds).slice(-2)}` : '00:00:00'}</p>

				<p
					className="lable"
				>Running:</p>
				<p
					className="value"
				>{this.props.gameRunning ? "Yes" : "No"}</p>

				<p
					className="lable"
				>Hints:</p>
				<p
					className="value"
				>{this.props.hints}</p>

<p
					className="lable"
				>Mistakes:</p>
				<p
					className="value"
				>{`${this.props.mistakes}/${this.props.maxMistakes}`}</p>
				<p
					className="lable"
				>Fields left:</p>
				<p
					className="value"
				>{`${this.props.fieldsMissing == null ? '81' : this.props.fieldsMissing} Fields`}</p>
			</div>
		)
	}
}

export default Statistics;
