import React from 'react';
import './App.css';

class Cell extends React.Component{
  constructor(props){
    super(props)
    this.state={
      row:this.props.row,
      column:this.props.column,
      number:this.props.number,
      opened:false,
      highlighted:false,
      hidden:this.props.hidden,
      inactive: this.props.inactive
    }
    this.openCloseCell = this.openCloseCell.bind(this)
  }

  getColor(row, column, opened, highlighted){
    /*
    if(this.props.hidden){
      return {
        background: "#b1db93",
        color: "white"
      }
    }
    */
    if(opened){
      return {
        background: "#db6060",
        color: "white"
      }
    }
    if(highlighted){
      return {
        background: "#e8a9a9",
        fontWeight: 700
      }
    }
    if((row<3&&column<3) || (row>5&&column<3) || (row>5&&column>5) || (row<3&&column>5) || (row>2&&row<6&&column>2&&column<6)){
      return {
        background: "#e8dcba"
      }
    } 
    else {
      return {
        background: "#d4c6bc"
      }
    }
  }

  openCloseCell(){
    this.props.closeAllCells();
    if(!this.state.opened){
      this.setState({
        opened:true
      })
      this.props.highlightSimilarNumbers(this.props.row, this.props.column, this.props.hidden, this.props.number)
    }
  }

  render(){
    return(
      <p
        className={this.props.inactive?'cell inactive':'cell'}
        key={`${this.props.row}-${this.props.column}`}
        style={this.getColor(this.props.row, this.props.column, this.state.opened,this.state.highlighted)}
        onClick={()=>this.openCloseCell()}
      >
        {this.props.hidden?undefined:this.props.number}
      </p>
    )
  }
}

export default Cell;
