import React from 'react';
import './App.css';
import Cell from './Cell';
import Settings from './Settings';
import Statistics from './Statistics';
import Buttons from './Buttons';
import react from 'react';

class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			array: null,//this.createArray(),
			mask: null,
			gameStarted: false,
			gameOver: false,
			gameWon: false,
			gameStartedAt: null,
			gameEndedAt: null,
			difficulty: 2,
			mistakes: 0,
			hints: 3,
			numbersSet: false,
			selectedField: [-1, -1],
			completedNumbers: [],
			fieldsMissing:81
		}

		this.cellRefs = [];
		this.fieldRef = react.createRef()

		this.createArray = this.createArray.bind(this)
		this.findMissingCell = this.findMissingCell.bind(this)
		this.deleteFromFirstMissingCell = this.deleteFromCell.bind(this)
		this.printArray = this.printArray.bind(this)
		this.closeAllCells = this.closeAllCells.bind(this)
		this.highlightSimilarNumbers = this.highlightSimilarNumbers.bind(this)
		this.changeDifficulty = this.changeDifficulty.bind(this)
		this.startGame = this.startGame.bind(this)
		this.checkInput = this.checkInput.bind(this)
		this.controllButtonHandler = this.controllButtonHandler.bind(this)
	}

	printArray() {
		console.log("Only available in Debug- and Development Versions", "Error")
	}

	createArray() {
		let array = [];
		for (let x = 0; x < 9; x++) {
			let tempArray = [];
			for (let y = 0; y < 9; y++) {
				tempArray.push(
					0
				)
			}
			array.push(tempArray)
		}

		let timeout = 0;
		array = this.fillArray(array)

		while (this.findMissingCell(array)[0] !== -1 && timeout < 5000) {
			array = this.deleteFromCell(array, ...this.findMissingCell(array))
			array = this.fillArray(array, ...this.findMissingCell(array))
			timeout++;
		}
		//console.log(`It took ${timeout} rounds`)
		if (this.findMissingCell(array)[0] !== -1) {
			return (this.createArray())
		}


		return array
	}

	createMask() {
		let mask = [];
		let tempFieldsMissing = 0;
		if (this.state.difficulty > 1) {
			for (let x = 0; x < 9; x++) {
				let tempMask = [];
				for (let y = 0; y < 9; y++) {
					tempMask.push(
						Math.floor(Math.random() * this.state.difficulty) !== 1
					)
					if(tempMask[y]){
						tempFieldsMissing++;
					}
				}
				mask.push(tempMask)
			}
		} else {
			for (let x = 0; x < 9; x++) {
				let tempMask = [];
				for (let y = 0; y < 9; y++) {
					tempMask.push(
						false
					)
				}
				mask.push(tempMask)
			}
			mask[8][5] = true
			mask[8][6] = true;
			mask[8][7] = true;
			mask[8][8] = true;
			tempFieldsMissing = 4;
		}
		this.setState({
			fieldsMissing:tempFieldsMissing
		})
		return mask
	}

	findMissingCell(array) {
		for (let x = 0; x < 9; x++) {
			for (let y = 0; y < 9; y++) {
				if (array[x][y] === undefined) {
					return ([x, 0])
				}
			}
		}
		return ([-1, -1])
	}

	deleteFromCell(array, startX, startY) {
		if (startX === -1 && startY === -1) {
			return array
		}
		for (let x = startX; x < 9; x++) {
			if (x === startX) {
				for (let y = startY; y < 9; y++) {
					array[x][y] = undefined;
				}
			} else {
				for (let y = 0; y < 9; y++) {
					array[x][y] = undefined;
				}
			}
		}
		return array
	}

	getCurrentField(array, row, column) {
		let field = [];
		let startX = Math.floor(row / 3)
		let startY = Math.floor(column / 3)
		for (let x = startX * 3; x < (startX * 3 + 3); x++) {
			for (let y = startY * 3; y < (startY * 3 + 3); y++) {
				if (array[x][y] > 0) {
					field.push(array[x][y]);
				} else {
					field.push(0);
				}
			}
		}
		return field
	}

	fillArray(array, startX = 0, startY = 0) {
		if (startX === -1 && startY === -1) {
			return array
		}
		for (let x = startX; x < 9; x++) {
			for (let y = startY; y < 9; y++) {
				let pool = [1, 2, 3, 4, 5, 6, 7, 8, 9];
				let currentRow = [];
				let currentColumn = [];
				let currentField = [];
				let place;
				for (let z = 0; z < 9; z++) {
					if (array[x][z] > 0) {
						currentRow.push(array[x][z])
					}
					if (array[z][y] > 0) {
						currentColumn.push(array[z][y])
					}
				}
				currentField = this.getCurrentField(array, x, y)
				pool = pool.filter(function (el) {
					return currentColumn.indexOf(el) < 0;
				});
				pool = pool.filter(function (el) {
					return currentRow.indexOf(el) < 0;
				});
				pool = pool.filter(function (el) {
					return currentField.indexOf(el) < 0;
				});
				place = Math.floor(Math.random() * (pool.length))
				array[x][y] = pool[place];
			}
		}
		return array;
	}

	changeDifficulty(e) {
		this.setState({
			difficulty: e
		})
	}

	startGame(e) {
		this.setState({
			array: this.createArray(),
			mask: this.createMask(),
			gameStarted: true,
			gameStartedAt: Date.now(),
			maxMistakes: 10-this.state.difficulty
		})
	}

	closeAllCells() {
		this.cellRefs.forEach((e) => {
			e.setState({
				opened: false,
				highlighted: false
			})
		})
	}

	highlightSimilarNumbers(row, column, hidden, num) {
		if (!hidden) {
			this.cellRefs.forEach((e) => {
				if (e.props.number === num && !e.props.hidden) {
					e.setState({
						highlighted: true
					})
				}
			})
		}
		this.setState({
			selectedField: [row, column]
		})
	}

	buildCells = (mode) => {
		let array = [];
		if (mode === "hidden") {
			for (let x = 0; x < 9; x++) {
				for (let y = 0; y < 9; y++) {
					array.push(
						<Cell
							key={`cell-hidden-${x}-${y}`}
							inactive={true}
						/>
					)
				}
			}
			return array;
		}
		for (let x = 0; x < 9; x++) {
			for (let y = 0; y < 9; y++) {
				array.push(
					<Cell
						ref={(ref) => {
							this.cellRefs[x * 9 + y] = ref;
							return true
						}}
						key={`cell-visible-${x}${y}`}
						row={x}
						column={y}
						number={this.state.array[x][y]}
						closeAllCells={this.closeAllCells}
						highlightSimilarNumbers={this.highlightSimilarNumbers}
						hidden={this.state.mask[x][y]}
					/>
				)
			}
		}
		return array;
	}

	checkInput(e) {
		if (this.state.mask[this.state.selectedField[0]][this.state.selectedField[1]]) {
			if (this.state.array[this.state.selectedField[0]][this.state.selectedField[1]] === e) {
				let tempMask = this.state.mask;
				tempMask[this.state.selectedField[0]][this.state.selectedField[1]] = false
				this.setState({
					mask: tempMask,
					fieldsMissing:this.state.fieldsMissing-1
				})
				this.fieldRef.current.className = 'field correct-input';
				setTimeout(() => {
					this.fieldRef.current.className = 'field';
				}, 2000);
				//console.log(`Correct hit! Now lets highlight the similar ones: row: ${this.state.selectedField[0]} column: ${this.state.selectedField[1]}, which is false hidden and num: ${e}`)
				this.highlightSimilarNumbers(this.state.selectedField[0], this.state.selectedField[1], false, e)
			} else {
				//console.log("That guess was wrong. I will add one mistake.")
				let tempMistakes = this.state.mistakes;
				tempMistakes++;
				this.setState({
					mistakes: tempMistakes
				})
				this.fieldRef.current.className = 'field failed-input';
				setTimeout(() => {
					this.fieldRef.current.className = 'field';
				}, 2000);
			}
		}
		for (let i = 1; i < 10; i++) {
			let counter = 0;
			for (let x = 0; x < 9; x++) {
				for (let y = 0; y < 9; y++) {
					if (this.state.array[x][y] === i && this.state.mask[x][y] === false) {
						counter++
					}
				}
			}
			if (counter === 9) {
				let tempCompletedNumbers = this.state.completedNumbers
				tempCompletedNumbers.push(i)
				this.setState({
					completedNumbers: tempCompletedNumbers
				})
			}
		}
	}

	giveHint() {
		if (this.state.hints > 0) {
			let tempHints = this.state.hints
			tempHints--
			let tempMask = this.state.mask
			tempMask[this.state.selectedField[0]][this.state.selectedField[1]] = false
			this.setState({
				mask: tempMask,
				hints: tempHints
			})
		}
	}

	controllButtonHandler(e) {
		if (e === "hint") {
			this.giveHint()
		}
		//console.log("please preform: " + e)
	}

	showGameEnd(cause){
		return (
			<div
				className={cause==="lost"?'game-end game-lost':'game-end game-won'}
			>
				<p>{cause==="lost"?"Game Over :(":"Game Won!"}</p>
				<ul>
					<li>
						Time: {(this.state.gameEndedAt - this.state.gameStartedAt)/1000} 
					</li>
					<li>
						Hints left: {this.state.hints} 
					</li>
					<li>
						Mistakes: {this.state.mistakes}
					</li>
					{cause==="lost"?<li>Fields Missing: {this.state.fieldsMissing}</li>:''}
				</ul>
			</div>
		)		
	}



	componentDidUpdate(){
		//console.log(this.state.fieldsMissing + " = fields missing")
		if((this.state.maxMistakes === this.state.mistakes)&&this.state.gameOver===false){
			this.setState({
				gameOver:true,
				gameStarted:false,
				gameEndedAt:Date.now()
			})	
		}
		if((this.state.fieldsMissing === 0)&&this.state.gameWon===false){
			this.setState({
				gameWon:true,
				gameStarted:false,
				gameEndedAt:Date.now()
			})	
		}
	}

	render() {
		return (
			<div
				className="window"
			>
				<div
					className="viewport"
				>
					<Settings
						onChangeDifficulty={this.changeDifficulty}
						onStartClicked={this.startGame}
					/>

					<div
						className="field"
						ref={this.fieldRef}
					>
						{this.state.gameStarted && !this.state.gameOver ? this.buildCells("visible") : this.buildCells("hidden")}
						{!this.state.gameStarted && this.state.gameOver ? this.showGameEnd("lost") : undefined}
						{!this.state.gameStarted && this.state.gameWon ? this.showGameEnd("won") : undefined}
						<Buttons
							onNumberButtonClicked={this.checkInput}
							onControllButtonClicked={this.controllButtonHandler}
							hints={this.state.hints}
							gameStarted={this.state.gameStarted}
							completedNumbers={this.state.completedNumbers}
						/>
					</div>

					<Statistics
						mistakes={this.state.mistakes}
						maxMistakes={10 - this.state.difficulty}
						gameRunning={this.state.gameStarted}
						gameStartedAt={this.state.gameStartedAt}
						hints={this.state.hints}
						gameOver={this.gameOver}
						fieldsMissing={this.state.fieldsMissing}
					/>
				</div>

				<div
					className="debugwindow"
				>
					<button
						key="button-create"
						onClick={() => this.createArray()}
					>
						Generieren
					</button>

					<button
						key="button-fix"
						onClick={() => this.setState({
							array: this.fillArray(this.state.array, ...this.findMissingCell(this.state.array))
						})}
					>
						Fix one Step
					</button>

					<button
						key="button-delete"
						onClick={() => this.setState({
							array: this.deleteFromCell(this.state.array, ...this.findMissingCell(this.state.array))
						})}
					>
						Delete from first missing cell
					</button>
				</div>
			</div>
		)
	}
}

export default App;
