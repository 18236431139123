import React from 'react';
import './App.css';


class Settings extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			difficulty: 2,
			locked: false
		}
		this.changeDifficulty = this.changeDifficulty.bind(this)
		this.onStartClicked = this.onStartClicked.bind(this)
	}

	changeDifficulty(event) {
		this.setState({
			difficulty: event.target.value
		})
		this.props.onChangeDifficulty(event.target.value)
	}

	getDifficultyExplanation(level) {
		return (
			`${Math.floor(100 - (100 / level))}% of all cells are hidden.
      \n${10 - level} Mistakes can be made.`
		)
	}

	onStartClicked(e) {
		this.props.onStartClicked(e)
		this.setState({
			locked: true
		})
	}

	render() {
		return (
			<div
				className="settings"
			>
				<p
					className={this.state.locked ? "lable locked" : "lable"}
				>1. Difficulty:</p>
				<select
					className={this.state.locked ? "locked" : ""}
					onChange={this.changeDifficulty}
					value={this.state.difficulty}
					disabled={this.state.locked}
				>
					
					<option
						value={2}
					>
						Easy
					</option>
					<option
						value={3}
					>
						Medium
					</option>
					<option
						value={5}
					>
						Hard
					</option>
					<option
						value={7}
					>
						Professional
					</option>
				</select>
				<p
					className={this.state.locked ? "value locked" : "value"}
				>
					{this.getDifficultyExplanation(this.state.difficulty)}
				</p>
				<p
					className={this.state.locked ? "lable locked" : "lable"}
				>2. General Hint</p>
				<p
					className={this.state.locked ? "value locked" : "value"}
				>
					Click a Number in field to highlight similar numbers
				</p>
				<p
					className={this.state.locked ? "lable locked" : "lable"}
				>3. Ready?</p>
				<p
					onClick={this.state.locked ? undefined : this.onStartClicked}
					className={this.state.locked ? "value locked" : "value clickable"}
					style={{
						backgroundColor:"cornflowerblue",
						margin:"10px 0 0 0",
						fontWeight: 700,
						color: "white",
						textAlign:"center",
						borderRadius: "7px"
					}}
				>
					Click to start
				</p>
				
			</div>
		)
	}
}

export default Settings;
