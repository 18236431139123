import React from 'react';
import './App.css';

class Buttons extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			difficulty: 2
		}
		this.onNumberButtonClicked = this.onNumberButtonClicked.bind(this)
		this.onControllButtonClicked = this.onControllButtonClicked.bind(this)
	}

	onNumberButtonClicked(e) {
		this.props.onNumberButtonClicked(e)
	}
	onControllButtonClicked(e) {
		this.props.onControllButtonClicked(e)
	}
	isNumberComplete(num) {
		return this.props.completedNumbers.find(e => e === num) === undefined
	}

	render() {
		let buttons = [];
		for (let i = 0; i < 9; i++) {
			buttons.push(
				<p
					className={this.props.gameStarted && this.isNumberComplete(i + 1) ? "button number-button" : "button number-button button-inactive"}
					onClick={(this.props.gameStarted && this.isNumberComplete(i + 1))? () => this.onNumberButtonClicked(i + 1) : undefined}
					value={i + 1}
					key={`button-${i + 1}`}
				>
					{i + 1}
				</p>
			)
		}
		return (
			<div
				className="buttons"
			>
				<div
					className="buttons-row"
				>
					{buttons}
				</div>
				<div
					className="buttons-row"
				>
					<p
						className={this.props.gameStarted ? "button controll-button" : "button controll-button button-inactive"}
						onClick={this.props.gameStarted ? () => this.onControllButtonClicked("undo") : undefined}
					>
						Undo
					</p>
					<p
						className={this.props.gameStarted ? "button controll-button" : "button controll-button button-inactive"}
						onClick={this.props.gameStarted ? () => this.onControllButtonClicked("redo") : undefined}
					>
						Redo
					</p>
					<p
						className={this.props.hints > 0 && this.props.gameStarted ? "button controll-button" : "button controll-button button-inactive"}
						onClick={this.props.hints > 0 && this.props.gameStarted ? () => this.onControllButtonClicked("hint") : undefined}
					>
						Hint
					</p>
				</div>
			</div>

		)
	}
}

export default Buttons;
